import { create } from "@mui/material/styles/createTransitions";
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  firstName: "",
  lastName: "",
  email: "",
  phone: "",
  address: "",
  city: "",
  state: "",
  country: "",
  pincode: "",
};

export const bookingDetailsSlice = createSlice({
  name: "bookingDetails",
  initialState,
  reducers: {
    addCustomerFirstName: (state, action) => {
      state.firstName = action.payload;
    },
    addCustomerLastName: (state, action) => {
      state.lastName = action.payload;
    },
    addCustomerEmail: (state, action) => {
      state.email = action.payload;
    },
    addCustomerContact: (state, action) => {
      state.phone = action.payload;
    },
  },
});

export const {
  addCustomerFirstName,
  addCustomerLastName,
  addCustomerEmail,
  addCustomerContact,
} = bookingDetailsSlice.actions;

export default bookingDetailsSlice.reducer;
