import { createSlice } from "@reduxjs/toolkit";
import moment from "moment";
import { militaryTimeFormat } from "../../../utils/Utils";

const todayDate = new Date();
const initialState = {
  checkinDate: moment(todayDate).format("ddd, DD MMM YYYY"),
  checkoutDate: moment(todayDate).add(1, "days").format("ddd, DD MMM YYYY"),
  checkinSlots: [],
  checkoutSlots: [],
  checkinTime: "",
  checkoutTime: "",
};

export const dateTimeSlice = createSlice({
  name: "dateTime",
  initialState,
  reducers: {
    updateCheckinDate: (state, action) => {
      state.checkinDate = action.payload;
    },
    updateCheckoutDate: (state, action) => {
      state.checkoutDate = action.payload;
    },
    updateCheckinSlots: (state, action) => {
      state.checkinSlots = action.payload;
      state.checkinTime = militaryTimeFormat(action.payload[0]);
      console.log(militaryTimeFormat(action.payload[0]));
    },
    updateCheckoutSlots: (state, action) => {
      state.checkoutSlots = action.payload;
      state.checkoutTime = militaryTimeFormat(action.payload[0]);
    },
    updateCheckinTime: (state, action) => {
      state.checkinTime = action.payload;
    },
    updateCheckoutTime: (state, action) => {
      state.checkoutTime = action.payload;
    },
  },
});

export const {
  updateCheckinDate,
  updateCheckinTime,
  updateCheckoutDate,
  updateCheckoutTime,
  updateCheckinSlots,
  updateCheckoutSlots,
} = dateTimeSlice.actions;

export default dateTimeSlice.reducer;
