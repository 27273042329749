import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  roomPrice: 0.0,
  addOns: 0.0,
  tax: 0.0,
  discount: 0.0,
  roomStays: "",
};

export const cartTotalSlice = createSlice({
  name: "cartTotal",
  initialState,
  reducers: {
    updateRoomPrice: (state, action) => {
      state.roomPrice = Number(action.payload.toFixed(2));
    },
    updateAddOns: (state, action) => {
      state.addOns = Number(action.payload.toFixed(2));
    },
    updateTax: (state, action) => {
      state.tax = Number(action.payload.toFixed(2));
    },
    updateDiscount: (state, action) => {
      state.discount = Number(action.payload.toFixed(2));
    },
    updateRoomStays: (state, action) => {
      state.roomStays = action.payload;
    },
  },
});

export const {
  updateRoomPrice,
  updateAddOns,
  updateTax,
  updateDiscount,
  updateRoomStays,
} = cartTotalSlice.actions;

export default cartTotalSlice.reducer;
