import { createSlice, current } from "@reduxjs/toolkit";

const initialState = {
  roomList: [],
};

export const bookedRoomSlice = createSlice({
  name: "bookedRoom",
  initialState,
  reducers: {
    addRooms: (state, action) => {
      state.roomList.push(action.payload);
      let index = 0;
      for (let room of state.roomList) {
        room["id"] = index;
        index += 1;
      }
    },
    removeRoom: (state, action) => {
      const updatedRoomList = state.roomList.filter(
        (item) => item?.id !== action.payload
      );
      let index = 0;
      for (let room of updatedRoomList) {
        room.id = index;
        index += 1;
      }
      state.roomList = updatedRoomList;
    },
    updateAdults: (state, action) => {
      switch (action.payload.type) {
        case "decrement":
          state.roomList.forEach((item) => {
            if (item.id === action.payload.id && item.adults > 1) {
              item.adults -= 1;
              const newMaxChildren = Math.min(
                Number(item.maxPossible) - Number(item.adults),
                Number(item.defaultMaxChildren)
              );
              item.maxChildren = newMaxChildren;
              item.children = 0;
            }
          });
          break;
        case "increment":
          state.roomList.forEach((item) => {
            if (
              item.id === action.payload.id &&
              item.adults < item.maxOccupancy
            ) {
              item.adults += 1;
              const newMaxChildren = Math.min(
                Number(item.maxPossible) - Number(item.adults),
                Number(item.defaultMaxChildren)
              );
              item.maxChildren = newMaxChildren;
              item.children = 0;
            }
          });
          break;
      }
    },
    updateChildren: (state, action) => {
      switch (action.payload.type) {
        case "decrement":
          state.roomList.forEach((item) => {
            if (item.id === action.payload.id && item.children > 0) {
              item.children -= 1;
            }
          });
          break;
        case "increment":
          state.roomList.forEach((item) => {
            console.log(item.maxChildren);
            if (
              item.id === action.payload.id &&
              item.children < item.maxChildren
            ) {
              item.children += 1;
            }
          });
          break;
      }
    },
  },
});

export const { addRooms, removeRoom, updateAdults, updateChildren } =
  bookedRoomSlice.actions;

export default bookedRoomSlice.reducer;
