import React from "react";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";

// Getting the hotel id from the url
const url = new URL(window.location.href);
const hotelId = url.searchParams.get("hotel_id");
// Private route in case no items are added into the cart
export const PrivateRoute = ({ children }) => {
  const bookedRoom = useSelector((state) => state.bookedRoom.roomList);
  if (bookedRoom.length > 0) {
    return children;
  }
  return <Navigate to={{ pathname: "/", search: `&hotel_id=${hotelId}` }} />;
};
