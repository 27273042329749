import { configureStore } from "@reduxjs/toolkit";
import hotelInfoReducer from "../slice/hotelInfoSlice";
import bookedRoomReducer from "../../components/Desktop/RoomCharter/bookedRoomSlice";
import bookedAddOnReducer from "../../components/Desktop/AmenitiesCharter/bookedAddOnSlice";
import bookingDetailsReducer from "../../components/Desktop/BookingDetails/bookingDetailsSlice";
import dateTimeReducer from "../../components/Common/DateTimeSlice/dateTimeSlice";
import cartTotalReducer from "../../components/Desktop/BookingCart/cartTotalSlice";
import bookingStatusReducer from "../slice/bookingStatusSlice";
import paymentReducer from "../slice/paymentSlice";

export const store = configureStore({
  reducer: {
    hotelInfo: hotelInfoReducer,
    bookedRoom: bookedRoomReducer,
    bookedAddOn: bookedAddOnReducer,
    bookingDetails: bookingDetailsReducer,
    dateTime: dateTimeReducer,
    cartTotal: cartTotalReducer,
    bookingStatus: bookingStatusReducer,
    payment: paymentReducer,
  },
  // middleware: (getDefaultMiddleware) =>
  //   getDefaultMiddleware({
  //     serializableCheck: false,
  //   }),
});
