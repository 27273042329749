import React, { lazy, Suspense, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useSelector } from "react-redux";
import { PrivateRoute } from "./route/PrivateRoute";
import axios from "axios";

// importing all the routes from the pages
const NoPage = lazy(() => import("./pages/NoPage"));
const Homepage = lazy(() => import("./pages/Homepage"));
const AddAmenitiesPage = lazy(() => import("./pages/AddAmenitiesPage"));
const AddBookingDetails = lazy(() => import("./pages/AddBookingDetails"));
const BookingConfirmationPage = lazy(() =>
  import("./pages/BookingConfirmationPage")
);
const BookingFailurePage = lazy(() => import("./pages/BookingFailurePage"));

const theme = createTheme({
  typography: {
    fontFamily: ["Maven Pro"].join(","),
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Suspense
        fallback={
          <h6
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}
          >
            Loading...
          </h6>
        }
      >
        <Router>
          <Routes>
            <Route exact path="/" element={<Homepage />} />
            <Route exact path="/addAmenities" element={<AddAmenitiesPage />} />
            <Route
              exact
              path="/addBookingDetails"
              element={
                <PrivateRoute>
                  <AddBookingDetails />
                </PrivateRoute>
              }
            />
            <Route
              exact
              path="/bookingConfirmation"
              element={
                <PrivateRoute>
                  <BookingConfirmationPage />
                </PrivateRoute>
              }
            />
            <Route
              exact
              path="/bookingFailure"
              element={
                <PrivateRoute>
                  <BookingFailurePage />
                </PrivateRoute>
              }
            />
            <Route path="*" element={<NoPage />} />
          </Routes>
        </Router>
      </Suspense>
    </ThemeProvider>
  );
}

export default App;
