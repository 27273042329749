import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  addOnsList: [],
};

export const bookedAddOnSlice = createSlice({
  name: "bookedAddOn",
  initialState,
  reducers: {
    addAddOns: (state, action) => {
      state.addOnsList.push(action.payload);
      let index = 0;
      for (let room of state.addOnsList) {
        room.id = index;
        index += 1;
      }
    },
    removeAddOn: (state, action) => {
      const updatedAddOnsList = state.addOnsList.filter(
        (item) => item?.id !== action.payload
      );
      let index = 0;
      for (let room of updatedAddOnsList) {
        room.id = index;
        index += 1;
      }
      state.addOnsList = updatedAddOnsList;
    },
    updateAddOnAdults: (state, action) => {
      switch (action.payload.type) {
        case "decrement":
          state.addOnsList.forEach((item) => {
            if (item.id === action.payload.id && item.adults > 1) {
              item.adults -= 1;
            }
          });
          break;
        case "increment":
          state.addOnsList.forEach((item) => {
            if (item.id === action.payload.id) {
              item.adults += 1;
            }
          });
          break;
      }
    },
    updateAddOnChildren: (state, action) => {
      switch (action.payload.type) {
        case "decrement":
          state.addOnsList.forEach((item) => {
            if (item.id === action.payload.id && item.children > 1) {
              item.children -= 1;
            }
          });
          break;
        case "increment":
          state.addOnsList.forEach((item) => {
            if (item.id === action.payload.id) {
              item.children += 1;
            }
          });
          break;
      }
    },
  },
});

export const {
  addAddOns,
  removeAddOn,
  updateAddOnAdults,
  updateAddOnChildren,
} = bookedAddOnSlice.actions;

export default bookedAddOnSlice.reducer;
