import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  status: "",
  bookingId: "",
  message: "",
};

export const bookingStatusSlice = createSlice({
  name: "bookingStatus",
  initialState,
  reducers: {
    addBookingStatus: (state, action) => {
      return void (state.status = action.payload);
    },
    addBookingId: (state, action) => {
      return void (state.bookingId = action.payload);
    },
    addMessage: (state, action) => {
      return void (state.message = action.payload);
    },
  },
});

export const { addBookingStatus, addBookingId, addMessage } =
  bookingStatusSlice.actions;

export default bookingStatusSlice.reducer;
