import moment from "moment";

export const toAbsoluteUrl = (pathname) => {
  return process.env.PUBLIC_URL + pathname;
};

export const handleSlotTimeFormat = (slotTime) => {
  return moment(slotTime.toString(), ["HH"]).format("hh A");
};

export const militaryTimeFormat = (slotTime) => {
  return moment(slotTime.toString(), ["hh A"]).format("HH");
};
