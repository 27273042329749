import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  hotelContent: {},
  hotelDetails: {},
  roomTypeCounts: {},
  hotelImages: [],
};

export const hotelInfoSlice = createSlice({
  name: "hotelInfo",
  initialState,
  reducers: {
    addHotelContent: (state, action) => {
      return void (state.hotelContent = action.payload);
    },
    addHotelDetails: (state, action) => {
      let availableRoomCount = {};
      Object.entries(action.payload?.roomTypeMap)?.map(
        ([key, value]) =>
          (availableRoomCount[key] = value?.combos[0]?.availableRooms)
      );
      return void ((state.hotelDetails = action.payload),
      (state.roomTypeCounts = availableRoomCount));
    },
    addHotelImages: (state, action) => {
      let orderedHotelImgs = action?.payload?.imageList?.sort(
        (a, b) => a.orderId - b.orderId
      );
      orderedHotelImgs = orderedHotelImgs.map((item) => item.imageUrl);
      return void (state.hotelImages = orderedHotelImgs);
    },
    decreaseRoomCount: (state, action) => {
      return void (state.roomTypeCounts[action.payload] -= 1);
    },
    restoreRoomCount: (state, action) => {
      return void (state.roomTypeCounts[action.payload] += 1);
    },
    reset: (state) => {
      return (state.hotelContent = {});
    },
  },
});

export const {
  addHotelContent,
  addHotelDetails,
  addHotelImages,
  decreaseRoomCount,
  restoreRoomCount,
  reset,
} = hotelInfoSlice.actions;

export default hotelInfoSlice.reducer;
